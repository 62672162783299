import React, {Fragment, useState} from 'react';
import {NavLink, Link} from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import useWindowSize from 'hooks/useWindowSize';

import {isMobile} from 'utils/size';
import SidebarMenu from './SidebarMenu';
import {appointmentsRoute} from 'utils/routes';
import {useAuth} from 'context/agent/auth-context';

const AuthHeader = () => {
  const {width} = useWindowSize();
  const mobile = isMobile(width);

  const [displayMenu, setDisplayMenu] = useState(false);

  const handleMenu = () => {
    setDisplayMenu((display) => !display);
  };

  const logoutSidebar = () => {
    handleMenu();
    logout();
  };

  const {isAuthenticated, authState, logout, isCallCenter, isAdmin} = useAuth();

  return (
    <Fragment>
      {isAuthenticated() && mobile && (
        <SidebarMenu active={displayMenu} handleMenu={handleMenu} logout={logoutSidebar} />
      )}
      <header className="header header--agent bg-white d-flex align-items-center fixed-top">
        <div className="container d-flex justify-content-between align-items-center">
          <Link to={isAdmin ? '/' : appointmentsRoute()}>
            <img
              src={require(`assets/images/pmm/logo/img-pmm-logo@2x.png`)}
              className="logo"
              alt="logo"
            />
          </Link>
          <div className="d-flex align-items-center">
            {isAuthenticated() &&
              (mobile ? (
                <Fragment>
                  <span role="button" className="icon-header fa fa-bars" onClick={handleMenu} />
                </Fragment>
              ) : (
                <Fragment>
                  <NavLink
                    className="text-decoration-none"
                    activeClassName="menu-active"
                    to={appointmentsRoute()}
                  >
                    <i className="icon-header fa fa-list-ul mr-2" />
                  </NavLink>
                  {!isCallCenter && (
                    <NavLink
                      className="text-decoration-none"
                      activeClassName="menu-active"
                      to="/agent/calendar"
                    >
                      <i className="icon-header fa fa-calendar-alt mr-2" />
                    </NavLink>
                  )}
                  <Dropdown>
                    <Dropdown.Toggle variant="none" id="menu-store">
                      <div className="header-store d-flex align-items-center">
                        {authState.storename && <span>{authState.storename}</span>}
                        <i className="icon-header icon-header-store fa fa-store-alt mx-2" />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="left">
                      <Dropdown.Item onClick={logout}>Αποσύνδεση</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Fragment>
              ))}
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default AuthHeader;
