const errorInit = {show: false, code: ''};

const errors = {
  1: 'Κάτι πήγε λάθος',
  100: 'Σφάλμα δεδομένων',
  101: 'Σφάλμα ασφάλειας δεδομένων',
  102: 'Λάθος δεδομένα',
  103: 'Το ραντεβού δεν βρέθηκε',
  104: 'Το κατάστημα δεν βρέθηκε',
  105: 'Το κατάστημα δεν βρέθηκε',
  106: 'Η ειδοποίηση δεν βρέθηκε',
  107: 'Η παραγγελία δεν βρέθηκε',
  200: 'Σφάλμα διαχείρισης',
  300: 'Σφάλμα επικύρωσης',
  301: 'Έχεις ήδη τρία ραντεβού την ίδια ημέρα',
  302: 'Έχεις κλείσει άλλο ραντεβού στο ίδιο κατάστημα και την ίδια ώρα',
  303: 'Το ραντεβού έχει τελειώσει',
  304: 'Το ραντεβού έχει ήδη ξεκινήσει',
  305: 'Το ραντεβού έχει ήδη επιβεβαιωθεί',
  306: 'Το ραντεβού έχει ήδη διαγραφεί',
  307: 'Το όνομα χρήστη ήδη χρησιμοποιείται',
  308: 'Διαφορετικό αναγνωριστικό ραντεβού',
  309: 'Η ημερομηνία δεν μπορεί να αλλάξει',
  310: 'Μη διαθέσιμες ώρες',
  311: 'Μη διαθέσιμη μέρα',
  312: 'Μη διαθέσιμη υπηρεσία',
  313: 'Ο κωδικός είναι λάθος',
  314: 'Ο κωδικός έχει λήξει',
  315: 'Άκυρος χρόνος ακύρωσης ραντεβού',
  316: 'Σφάλμα αναζήτησης',
  317: 'Δεν μπορείτε να δημιουργήσετε νέο κωδικό για 1 λεπτό',
  318: 'Έχετε φτάσει το όριο των επαναπροσπαθειών. Ο κωδικός απνεργοποιήθηκε.',
  319: 'Έχετε φτάσει το όριο των επαναπροσπαθειών. Δεν μπορείτε να δημιουργήσετε νέο κωδικό.',
  320: 'Δεν μπορείτε να δημιουργήσετε νέο κωδικό για 1 λεπτό',
  321: 'Το ραντεβού δεν έχει επιβεβαιωθεί',
  324: 'Δεν μπορεί να υπάρχει αρνητική διαθεσιμότητα',
  400: 'Σφάλμα επικοινωνίας',
  401: 'Email, σφάλμα ενημέρωσης',
  402: 'Email, σφάλμα επιβεβαίωσης',
  403: 'Email, σφάλμα ακύρωσης',
  500: 'Σφάλμα δεδομένων',
  600: 'Σφάλμα ασφαλείας'
};

export {errorInit, errors};
