import React, {lazy, Suspense} from 'react';
import {Switch, Route, useRouteMatch, Redirect} from 'react-router-dom';

import {useAuth} from 'context/agent/auth-context';
import {AppointmentProvider} from 'context/agent/appointment-context';
import {FetchProvider} from 'context/agent/fetch-context';
import Login from 'components/Agent/Login';
import Loader from 'components/common/Loader';
import {isMobile} from 'utils/size';

const AgentRouter = lazy(() => import('./Router'));

export const AuthenticatedRoute = ({component: Component, ...rest}) => {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={(ownProps) => {
        if (!auth.isAuthenticated()) {
          return <Redirect to="/agent/login" />;
        }

        if (auth.isAdmin) {
          return <Redirect to="/admin" />;
        }

        return <Component {...ownProps} {...rest} />;
      }}
    ></Route>
  );
};

const Agent = () => {
  const {url} = useRouteMatch();
  const loaderIconDimension = isMobile ? 40 : 80;

  return (
    <Suspense
      fallback={
        <Loader
          wrapperClasses="d-flex flex-column justify-content-center align-items-center h-100 fs-20"
          width={loaderIconDimension}
          height={loaderIconDimension}
        />
      }
    >
      <Switch>
        <Route exact path={`${url}/login`} component={Login} />
        <AppointmentProvider>
          <FetchProvider>
            <Switch>
              <AuthenticatedRoute path={url} component={AgentRouter} />
            </Switch>
          </FetchProvider>
        </AppointmentProvider>
      </Switch>
    </Suspense>
  );
};

export default Agent;
