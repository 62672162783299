const injectScript = (url, callback) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.dataset.id = 'script-to-delete';

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName('body')[0].appendChild(script);
};

const removeScript = () => {
  document
    .querySelectorAll('[data-id="script-to-delete"]')
    .forEach((e) => e.parentNode.removeChild(e));
  document.querySelectorAll('.pac-container').forEach((e) => e.parentNode.removeChild(e));
  delete window.google;
};

export {removeScript, injectScript};

export default injectScript;
