import React from 'react';
import {Route} from 'react-router-dom';

import Agent from 'components/Agent';
import Admin from 'components/Admin';
import AgentLayout from 'components/layouts/agent-layout';
import {AuthProvider} from 'context/agent/auth-context';

const Authentication = () => {
  return (
    <AuthProvider>
      <Route path="/admin">
        <Admin />
      </Route>
      <Route path="/agent">
        <AgentLayout>
          <Agent />
        </AgentLayout>
      </Route>
    </AuthProvider>
  );
};

export default Authentication;
