import React, {lazy, Suspense} from 'react';
import {Route, Redirect, useRouteMatch} from 'react-router-dom';
import Loader from 'components/common/Loader';

import {useAuth} from 'context/agent/auth-context';
import {isMobile} from 'utils/size';

const Administrator = lazy(() => import('./Router'));

const AdminRoute = ({component: Component, ...rest}) => {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={(ownProps) =>
        auth.isAuthenticated() && auth.isAdmin ? (
          <Component {...ownProps} {...rest} />
        ) : (
          <Redirect to="/agent/login" />
        )
      }
    ></Route>
  );
};

const loaderIconDimension = isMobile ? 40 : 80;

const AdminRoutes = () => {
  const {url} = useRouteMatch();

  return (
    <Suspense
      fallback={
        <Loader
          wrapperClasses="d-flex flex-column justify-content-center align-items-center h-100 fs-20"
          width={loaderIconDimension}
          height={loaderIconDimension}
        />
      }
    >
      <AdminRoute path={url} component={Administrator} />
    </Suspense>
  );
};

export default AdminRoutes;
