import moment from 'moment';

const dateFormatted = (date) => moment(date || new Date()).format('YYYY-MM-DD');

const timelineRoute = (dateParam) => {
  return `/agent/timeline/${dateFormatted(dateParam)}`;
};

const dashboardRoute = (fromDate, toDate) => {
  return `/agent/dashboard/${dateFormatted(
    fromDate || moment().subtract(1, 'months')
  )}to${dateFormatted(toDate)}`;
};

const adminRoute = '/admin';
const loginNextRoute = (isAdmin) => (isAdmin ? adminRoute : timelineRoute());

const appointmentsRoute = (dateParam) => {
  return `/agent/appointments/${dateFormatted(dateParam)}`;
};

export {adminRoute, timelineRoute, dashboardRoute, loginNextRoute, appointmentsRoute};
